<template>
  <div class="filing">
    <img src="../../assets/img/filing/filing.jpg" alt="" />
  </div>
</template>

<script>
export default {
  data: () => ({
    // filing: require('../../assets/img/filing/filing.jpg'),
  }),
}
</script>

<style lang="scss" scoped>
.filing {
  height: 80vh;
  width: 100%;
  overflow: hidden;
  img {
    margin: 0 auto;
    height: 100%;
    object-fit: contain;
    object-position: center;
    // transform: rotate(-.8deg) scale(1.02);
  }
}
</style>
